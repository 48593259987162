<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card login-dark">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img
                class="img-fluid for-light"
                src="assets/images/logo/logo.png"
                alt="looginpage"
              />
              <img
                class="img-fluid for-dark"
                src="assets/images/logo/logo_dark.png"
                alt="looginpage"
              />
            </a>
          </div>
          <div class="login-main">
            <div class="theme-form text-center">
              <h4>Connectez-vous</h4>
              <p>Console Monkata</p>
              <div class="form-group mb-0">
                <div class="text-end mt-3">
                  <button
                    class="btn btn-primary d-block w-100"
                    (click)="login()"
                    type="button"
                  >
                    <span>Connectez-vous avec Monkata</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
